'use client';

import React from 'react';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import JsonLd from '@src/components/Shared/JsonLd';
import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import NextImage from '@src/components/Shared/NextImage';
import RenderButtons from '@src/components/Shared/RenderButtons';
import ImageTextSecondary from '@src/components/Sections/ImageTextSecondary';
import CallToAction from '@src/components/Sections/CallToAction';
import ClientSuccess from '@src/components/Sections/ClientSuccess';
import Testimonials from '@src/components/Sections/Testimonials';
import usePreviewContent from '@src/hooks/usePreviewContent';
import constructURL from '@src/utils/constructURL';
import { HomePageClientPropTypes } from './HomePage.types';
import Vald from '@src/components/Icons/Vald';
const HomePageClient: React.FC<HomePageClientPropTypes> = ({
  data
}) => {
  // Enable live updates for the post
  const page = usePreviewContent({
    content: data?.page
  });

  // Set the page view path for analytics tracking
  const {
    setPathPageView,
    setContentType
  } = useTrackingCode();
  setPathPageView(constructURL({
    languageCode: page?.languageCode,
    slug: '/',
    baseURL: ''
  }));
  setContentType('site-page');
  const homeJsonLd = JsonLd({
    name: page?.pageName,
    pageUrl: `/${page?.languageCode?.toLowerCase()}${page?.slug === '/' ? '' : page?.slug}`,
    thumbnailUrl: page?.seoMetadata?.previewImage?.url,
    description: page?.seoMetadata?.description,
    datePublished: page?.sys?.firstPublishedAt,
    dateModified: page?.sys?.publishedAt,
    locale: page?.languageCode?.toLowerCase()
  });
  return <>
      <script type='application/ld+json' dangerouslySetInnerHTML={{
      __html: JSON.stringify(homeJsonLd)
    }} />

      <Container attr='hero-component' id={page?.hero?.items?.[0]?.scrollTo?.slice(1)} className='relative !z-[10] max-h-[85rem] pt-[9rem] sm:pt-[6.4rem] lg:pt-[8.2rem] 2xl:pt-[9.6rem]' data-sentry-element="Container" data-sentry-source-file="HomePage.client.tsx">
        <SectionHeader className='relative z-[10]' headingLevel='h1' title={page?.hero?.items?.[0]?.title} titleClassName='text-[4rem] leading-[4.2rem] mb-[2.4rem] md:leading-[4.6rem] font-[300] max-w-[32rem] xsm:max-w-[42rem] md:max-w-[85.8rem] md:leading-[6.2rem] md:text-[6rem] lg:max-w-[85rem] lg:text-[8rem] lg:leading-[8rem] lg:tracking-[0.152rem] 2xl:text-[8.2rem] 2xl:leading-[7.6rem] 2xl:tracking-[0.082rem] 2xl:max-w-[88.8rem] 2xl:mb-[5.2rem]' childrenClassName='max-w-[34.2rem] mb-[4.2rem] md:max-w-[56.6rem] md:leading-[2.7rem] md:!text-[1.8rem] lg:mb-[0] lg:max-w-[53.3rem] 2xl:leading-[3.6rem] 2xl:!text-[2.4rem]' align='left' data-sentry-element="SectionHeader" data-sentry-source-file="HomePage.client.tsx">
          <>
            <RenderBodyCopy className='md:!text-[1.8rem] 2xl:!text-[2.4rem] 2xl:leading-[3.6rem]' bodyCopy={page?.hero?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HomePage.client.tsx" />
          </>
        </SectionHeader>
        <NextImage className='relative z-[5] mx-[auto] min-h-[29.4109rem] max-w-[65rem] md:min-h-[55.2484rem] lg:right-[-19rem] lg:top-[-4rem] lg:min-h-[60.2406rem] lg:max-w-[70rem] 2xl:right-[-32rem] 2xl:top-[-31.1rem] 2xl:min-h-[73.1625rem] 2xl:max-w-[85.016rem] 3xl:right-[-20rem] 3xl:top-[-32.2rem] 3xl:min-h-[77.4531rem] 3xl:max-w-[90rem]' src={page?.hero?.items?.[0]?.heroImage?.url} alt={page?.hero?.items?.[0]?.heroImage?.description} priority aspectRatio={page?.hero?.items?.[0]?.heroImage?.width / page?.hero?.items?.[0]?.heroImage?.height} width={page?.hero?.items?.[0]?.heroImage?.width} data-sentry-element="NextImage" data-sentry-source-file="HomePage.client.tsx" />
      </Container>

      <Container className='2xl:mt-[-20rem] 2xl:pb-[6rem] 2xl:pt-[15rem] 3xl:mt-[-17.57rem]' background='2xl:bg-background-tertiary' id={page?.siteOptions?.items?.[0]?.scrollTo?.slice(1)} data-sentry-element="Container" data-sentry-source-file="HomePage.client.tsx">
        <div className='grid gap-y-[2.4rem] md:mb-[0] md:grid-cols-3 md:gap-[1.6rem] md:gap-y-[unset] lg:mx-auto lg:max-w-[101.2rem] 2xl:gap-[3.6rem]'>
          {page?.siteOptions?.items?.[0]?.topicCollection?.items?.map((item, index) => {
          return <div key={`site-option-${index}`} className='md:mb-[0] md:flex md:flex-col'>
                <NextImage className='mb-[2.4rem] rounded-[0.4rem] 2xl:mb-[2.8rem]' src={item.mediaPrimary?.url} alt={item.mediaPrimary?.description} aspectRatio={item.mediaPrimary?.width / item.mediaPrimary?.height} width={item.mediaPrimary?.width} />

                <h2 className='mb-[1.8rem] text-left text-[2.2rem] font-[400] leading-[1] tracking-[-0.044rem] lg:text-[2.8rem]'>
                  {item.title}
                </h2>

                <RenderBodyCopy className='mb-[2.4rem] flex-1 text-[1.6rem] leading-[2.4rem] text-body-copy sm:max-w-[40rem] md:mx-[unset] md:max-w-[unset]  md:text-[1.2rem] md:leading-[1.8rem] lg:text-left lg:text-[1.4rem] lg:leading-[2.1rem] 2xl:text-[1.6rem] 2xl:leading-[2.4rem]' bodyCopy={item?.bodyCopy?.json?.content} />

                <RenderButtons className='mt-auto' items={item?.buttonCollection?.items} />
              </div>;
        })}
        </div>
      </Container>

      <Container attr='objective-measurement-component' id={page?.dataInformed?.items?.[0]?.scrollTo?.slice(1)} className='md:flex md:flex-col' data-sentry-element="Container" data-sentry-source-file="HomePage.client.tsx">
        <NextImage className='mb-[2.4rem] md:order-[1] md:mb-[unset] lg:px-[15%]' src={page?.dataInformed?.items?.[0]?.mediaPrimary?.url} alt={page?.dataInformed?.items?.[0]?.mediaPrimary?.description} aspectRatio={page?.dataInformed?.items?.[0]?.mediaPrimary?.width / page?.dataInformed?.items?.[0]?.mediaPrimary?.height} width={page?.dataInformed?.items?.[0]?.mediaPrimary?.width} // Set actual width
      data-sentry-element="NextImage" data-sentry-source-file="HomePage.client.tsx" />

        <SectionHeader className='md:mb-[3.4rem] 2xl:!mb-[3.9rem]' pretitle={page?.dataInformed?.items?.[0]?.preTitle} title={page?.dataInformed?.items?.[0]?.title} titleClassName='text-[2.2rem] leading-[2.6rem] !mb-[1.8rem] md:leading-[1] md:text-[2.8rem] lg:text-[4.8rem] lg:tracking-[-0.096rem] lg:mb-[3.2rem] 2xl:text-[7.2rem] 2xl:tracking-[-0.072rem] 2xl:!mb-[3.6rem]' childrenClassName='lg:max-w-[74.6rem]' align='center' data-sentry-element="SectionHeader" data-sentry-source-file="HomePage.client.tsx">
          <>
            <RenderBodyCopy bodyCopy={page?.dataInformed?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HomePage.client.tsx" />
          </>
        </SectionHeader>
      </Container>

      <Container id={page?.solutions?.items?.[0]?.scrollTo?.slice(1)} attr='solution-header-component' className='!mb-[2.4rem]' data-sentry-element="Container" data-sentry-source-file="HomePage.client.tsx">
        <SectionHeader title={page?.solutions?.items?.[0]?.title} titleClassName='text-[2.2rem] leading-[2.6rem] !mb-[1.8rem] md:leading-[3.6rem] lg:leading-[5.4rem] md:text-[2.8rem] lg:text-[4.8rem] 2xl:leading-[7.2rem] lg:tracking-[-0.096rem] lg:mb-[3.2rem] 2xl:text-[7.2rem] 2xl:tracking-[-0.072rem] 2xl:!mb-[3.9rem]' childrenClassName='lg:max-w-[74.6rem]' align='center' data-sentry-element="SectionHeader" data-sentry-source-file="HomePage.client.tsx">
          <>
            <RenderBodyCopy bodyCopy={page?.solutions?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HomePage.client.tsx" />

            <RenderButtons className='justify-left md:justify-center' items={page?.solutions?.items?.[0]?.buttonCollection?.items} data-sentry-element="RenderButtons" data-sentry-source-file="HomePage.client.tsx" />
          </>
        </SectionHeader>
        <div className='!mb-[8rem] mt-[2.4rem] grid gap-x-[2.4rem] gap-y-[4rem] md:mb-[0] md:grid-cols-3 lg:mx-auto lg:max-w-[101.2rem] 2xl:gap-[3.6rem]'>
          {page?.solutions?.items?.[0]?.extraAssetsCollection?.items?.map(solution => {
          return <div key={`${solution?.sys?.id}-vald-solutions`}>
                <NextImage className='mb-[2.4rem] rounded-t-[0.6rem] bg-[#F9F9F9]' src={solution?.mediaPrimary?.url} alt={solution?.mediaPrimary?.description} aspectRatio={solution?.mediaPrimary?.width / solution?.mediaPrimary?.height} width={solution?.mediaPrimary?.width} />
                <h3 className='mb-[1.6rem] text-[1.6rem] font-[400] tracking-[-0.028rem] md:text-left lg:text-[2rem] lg:leading-[2.1rem] lg:tracking-[-0.04rem] 2xl:text-[2.2rem] 2xl:leading-[2.31rem] 2xl:tracking-[-0.044rem]'>
                  {solution?.title}
                </h3>
                <RenderBodyCopy className='text-left text-[1.6rem] leading-[1.5] text-body-copy sm:max-w-[33rem] md:max-w-[unset] md:text-left lg:text-[1.4rem] lg:leading-[2.1rem] 2xl:text-[1.6rem] 2xl:leading-[2.4rem]' bodyCopy={solution.bodyCopy?.json?.content} />
              </div>;
        })}
        </div>
      </Container>

      <ImageTextSecondary background='bg-background-tertiary' className='!overflow-visible py-[4rem] lg:py-[0rem]' id={page?.ourClients?.items?.[0]?.scrollTo?.slice(1)} title={page?.ourClients?.items?.[0]?.title} titleClassName='text-[3.8rem] leading-[1] tracking-[-0.038rem] mb-[2.4rem] md:text-[3.8rem] md:tracking-[-0.038rem] md:text-[3.8rem] lg:text-[5rem] lg:tracking-[-0.05rem] 2xl:text-[7.2rem] 2xl:leading-[7rem] 2xl:tracking-[-0.072rem]' imgURL={page?.ourClients?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.ourClients?.items?.[0]?.mediaPrimary?.description ?? ''} childrenElements={<>
            <RenderBodyCopy className='mb-[2.4rem] text-[1.4rem] font-[400] leading-[2.1rem] md:text-[1.4rem] md:leading-[2.1rem] lg:text-[1.6rem] lg:leading-[2.4rem] 2xl:text-[2.2rem] 2xl:leading-[3.3rem]' bodyCopy={page?.ourClients?.items?.[0]?.bodyCopy?.json?.content} />

            <div className='gap-4 mb-[2.4rem] grid max-w-[60rem] grid-cols-2 gap-y-[2.4rem] lg:mb-[3rem] 2xl:mb-[4rem]'>
              {page?.ourClients?.items?.[0]?.extraAssetsCollection?.items?.map((asset, index) => {
          return <div key={index} className='space-y-[0.5rem]'>
                    <p className='text-[2.4rem] font-[500] leading-[3.6rem] tracking-[-0.0264rem] text-primary lg:text-[4.8rem] lg:leading-[5.4rem] lg:tracking-[-0.0396rem]'>
                      {asset?.title}
                    </p>

                    <RenderBodyCopy bodyCopy={asset.bodyCopy?.json?.content} className='text-[1.2rem] font-[200] leading-[-0.0132rem] tracking-[-0.0132] lg:text-[1.6rem]' />
                  </div>;
        })}
            </div>

            <p className='flex items-center pb-[4rem] text-[2rem] font-[400] leading-[3rem] tracking-[-0.022rem] text-body-copy--dark md:pb-[0] lg:text-[3rem] 2xl:text-[4rem] 2xl:tracking-[-0.044rem]'>
              <span>Trust</span>
              <Vald className='mx-[0.5rem] inline-block w-[6.5rem] lg:mx-[1rem] lg:w-[10rem] 2xl:mx-[2.5rem] 2xl:scale-[1.3] 2xl:transform' />
              <span>Technologies</span>
            </p>
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="HomePage.client.tsx" />

      <Testimonials id={page?.testimonials?.items?.[0]?.scrollTo?.slice(1)} testimonials={page?.testimonials?.items?.[0]?.topicCollection?.items} data-sentry-element="Testimonials" data-sentry-source-file="HomePage.client.tsx" />

      <NextImage id={page?.weExist?.items?.[0]?.scrollTo?.slice(1)} src={page?.weExist?.items?.[0]?.mediaPrimary?.url} alt='A portrait' className='mb-[4.5rem] h-[40rem] md:h-[45rem] lg:h-[54.3rem] 2xl:mb-[5.6rem] [&>img]:object-top' data-sentry-element="NextImage" data-sentry-source-file="HomePage.client.tsx">
        <Container className='!mb-[0] h-[100%]' data-sentry-element="Container" data-sentry-source-file="HomePage.client.tsx">
          <SectionHeader className='h-[100%]' preTitleClassName='mb-[1.6rem]' title={page?.weExist?.items?.[0]?.title} titleClassName='text-left md:text-center text-[2.2rem] leading-[2.6rem] tracking-[-0.0418] text-body-copy--light font-[300] text-[1.8rem] md:leading-[3rem] md:text-[2.8rem] lg:max-w-[90rem] lg:text-[4.8rem] 2xl:text-[7.2rem] lg:leading-[5rem] 2xl:leading-[7.56rem]' childrenClassName='relative !max-w-[88.8rem]' titleHighlightBold align='center' data-sentry-element="SectionHeader" data-sentry-source-file="HomePage.client.tsx">
            <RenderBodyCopy className='mx-auto mt-[2.4rem] !max-w-[100%] text-left text-[1.6rem] font-[200] text-body-copy--light md:text-center md:text-[2.4rem]  2xl:mt-[3.2rem] 2xl:max-w-[88.8rem]' bodyCopy={page?.weExist?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HomePage.client.tsx" />
            <RenderButtons className='justify-left md:justify-center' items={page?.weExist?.items?.[0]?.buttonCollection?.items} data-sentry-element="RenderButtons" data-sentry-source-file="HomePage.client.tsx" />
          </SectionHeader>
        </Container>
      </NextImage>

      <ClientSuccess id={page?.clientSuccess?.items?.[0]?.scrollTo?.slice(1)} data={page?.clientSuccess?.items?.[0]} data-sentry-element="ClientSuccess" data-sentry-source-file="HomePage.client.tsx" />

      <CallToAction attr='call-to-action-component' id={page?.callToAction?.items?.[0]?.scrollTo?.slice(1)} title={page?.callToAction?.items?.[0]?.title} bodyCopy={page?.callToAction?.items?.[0]?.bodyCopy?.json?.content} buttons={page?.callToAction?.items?.[0]?.buttonCollection?.items} data-sentry-element="CallToAction" data-sentry-source-file="HomePage.client.tsx" />
    </>;
};
export default HomePageClient;